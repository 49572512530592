<template>
  <v-app id="login-form" class="primary">
    <v-main>
      <v-app-bar color="white" dark absolute>
        <v-toolbar-title>
          <v-img :src="logoHorizontal" contain class="logoBar"></v-img>
        </v-toolbar-title>
      </v-app-bar>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-img
              src="assets/img/logo-horizontal.jpeg"
              class="logo"
              height="250"
              contain
            ></v-img>
            <v-card class="elevation-5 ma-5 pt-4">
              <v-card-text>
                <div class="layout column align-center"></div>
                <v-form>
                  <v-text-field
                    prepend-icon="mdi-email"
                    name="login"
                    label="E-mail"
                    type="text"
                    v-model="model.username"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Senha"
                    id="password"
                    type="password"
                    v-model="model.password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <recuperar-senha :email="model.username"></recuperar-senha>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="login" :loading="loading"
                  >Login</v-btn
                >
                
              </v-card-actions>
            </v-card>
            <!-- <v-card-text>
              <div class="layout column align-center">
                <p class="pl-3 pr-5 text-login">
                  Uma plataforma exclusiva, desenvolvida para levar até você,
                  profissional da psicologia, muito conhecimento e inovação.
                </p>
              </div>
              <v-spacer></v-spacer>
              <v-divider class="purple darken-2"></v-divider>
            </v-card-text> -->
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// Utilities
import { mapState, mapMutations } from "vuex";
import firebase from "firebase/app";
import db from "firebase/database";

import toastr from "toastr";
import RecuperarSenha from '@/views/default/pages/components/RecuperarSenha.vue';
export default {
  components: { RecuperarSenha },
  data: () => ({
    loading: false,
    model: {
      username: "",
      password: "",
    },
  }),
  computed: {
    ...mapState(["barColor", "barImage", "logoHorizontal", "user"]),
  },
  methods: {
    ...mapMutations({
      setUser: "SET_USER",
    }),
    setFormatedUser(payload) {
      let user = {};
      user.phoneNumber = payload.phoneNumber || "";
      user.cpfcnpj = payload.cpfcnpj || "";
      user.photoURL = payload.photoURL || "";
      user.uid = payload.uid || "";
      user.displayName = payload.displayName || "";
      user.email = payload.email || "";
      user.isAdmin = payload.isAdmin;
      user.cursos = payload.cursos || [];
      user.franquia = payload.franquia || {};
      // console.log(payload)
      if(payload.refreshToken){
        user.refreshToken = payload.refreshToken
      }else{
        // console.log(this.user)
        user.refreshToken = this.user.refreshToken
      }
      this.setUser(user);
    },
    login() {
      this.loading = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.model.username.trim(), this.model.password.trim())
        .then((user) => {
          // console.log(user)
          this.setFormatedUser(user.user);
          firebase
            .database()
            .ref("/users/" + user.user.uid)
            .once("value")
            .then((snapshot) => {
              if (snapshot.val()) {
                this.setFormatedUser(snapshot.val());
              }
            });
          localStorage.setItem("user", JSON.stringify(user.user));
          if (user.additionalUserInfo.isNewUser) {
            this.$router.replace("/pages/profile");
          } else {
            this.$router.replace("/");
          }

          //Login Não Existe
          this.loading = false;
          //Zerando o Formulário
          this.model.username = "";
          this.model.password = "";
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "MCS Assessoria e Treinamentos"
          );
          this.loading = false;
          console.log(error);
          //     this.$store.commit('loading',false)
          //     toastr.error('Falha de conexão com a API','Varejão da Sucata')
        });
    },
  },
};
</script>
<style scoped lang="css">
.logoBar {
  height: 64px;
  width: 190px;
}
.text-login {
  color: #1dc765;
  font-weight: 500;
}
#login-form {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
  background: url("/assets/img/copia-de-seguranca-HOME.png");
  background-size: contain;
  background-position: bottom center;
}
</style>
